export const UNKNOWN = 'unknown'

export const AXIOS_INTERCEPTOR_UNDEFINED_ERROR = 'AXIOS_INTERCEPTOR_UNDEFINED_ERROR'

export const NETWORK = 'network'

export const NOT_STAFF = 'not_staff'
export const FORBIDDEN = 'forbidden'
export const BAD_GATEWAY = 'bad_gateway'
export const GATEWAY_TIMEOUT = 'gateway_timeout'

export const I18N_UNTRANSLATED_LINK = 'i18n_untranslated_link'
export const I18N_UNTRANSLATED_FIELD = 'i18n_untranslated_field'

export const AUTH_BAD_CREDENTIALS = 'auth_bad_credentials'
export const AUTH_INVALID_GRANT = 'auth_invalid_grant'
export const AUTH_MISSING_CREDENTIALS = 'auth_missing_credentials'
export const AUTH_UNKNOWN = 'auth_unknown'

export const TRUSTED_SHOP_TOO_MANY = 'trusted_shop_too_many'
export const TRUSTED_SHOP_INVALID_ID = 'trusted_shop_invalid_id'
export const TRUSTED_SHOP_UNKNOWN = 'trusted_shop_unknown'

export const CHAT_UNKNOWN = 'chat_unknown'

export const USER_UNKNOWN = 'user_unknown'

export const AXEPTIO_UNKNOWN = 'axeptio_unknown'

export const REVIEW_UNKNOWN = 'review_unknown'
export const REVIEW_REPORT = 'review_report'

export const CONTENT_UNKNOWN = 'content_unknown'

export const GOOGLE_MAPS_UNKNOWN = 'google_maps_unknown'

export const INSURANCE_UNKNOWN = 'insurance_unknown'
export const RENTAL_COVER_SERVICE_UNAVAILABLE = 'rental_cover_service_unavailable'

export const BOOKING_GUEST_UNKNOWN = 'booking_guest_unknown'
export const BOOKING_GUEST_VALIDATE = 'booking_guest_validate'
export const BOOKING_GUEST_PRICE = 'booking_guest_prices'
export const BOOKING_GUEST_INVALID_COUPON_CODE = 'booking_guest_invalid_coupon_code'

export const BOOKING_OWNER_UNKNOWN = 'booking_owner_unknown'
export const BOOKING_OWNER_CANCEL = 'booking_owner_cancel'

export const CAMPER_UNKNOWN = 'camper_unknown'
export const CAMPER_NOT_FOUND = 'camper_not_found'
export const CAMPER_ID_NOT_A_NUMBER = 'camper_id_not_a_number'
export const CAMPER_DELETED = 'camper_deleted'

export const WISHLIST_UNKNOWN = 'wishlist_unknown'

export const NEWSLETTER_UNKNOWN = 'newsletter_unknown'
export const NEWSLETTER_EMAIL_ALREADY_EXISTS = 'newsletter_email_already_exists'
export const NEWSLETTER_EMAIL_IS_INVALID = 'newsletter_email_is_invalid'

export const BRAND_UNKNOWN = 'brand_unknown'

export const MANUFACTURER_UNKNOWN = 'manufacturer_unknown'

export const VEHICLE_UNKNOWN = 'vehicle_unknown'
export const VEHICLE_MISSING_LOCATION = 'vehicle_missing_location'

export const DOCUMENT_UNKNOWN = 'document_unknown'

export const SEARCH_UNKNOWN = 'search_unknown'
export const SEARCH_INVALID_PAGE = 'search_invalid_page'

export const PASSWORD_UNKNOWN = 'password_unknown'

export const MANGOPAY_UNKNOWN = 'mangopay_unknown'

export const CERTIFY_PHONE_UNKNOWN = 'certify_phone_unknown'
export const CERTIFY_PHONE_BAD_CODE = 'certify_phone_bad_code'

export const EARNINGS_UNKNOWN = 'earnings_unknown'

export const CLIPBOARD_UNKNOWN = 'clipboard_unknown'

export const GEOLOCATION_UNKNOWN = 'geolocation_unknown'
export const GEOLOCATION_PERMISSION_DENIED = 'geolocation_permission_denied'
export const GEOLOCATION_POSITION_UNAVAILABLE = 'geolocation_position_unavailable'
export const GEOLOCATION_TIMEOUT = 'geolocation_timeout'

export const CAMPER_PRICE_UNKNOWN = 'camper_price_unknown'
export const CAMPER_PRICE_INVERTED_DATES = 'camper_price_inverted_dates'
export const CAMPER_PRICE_INVALID_DOCUMENTS = 'camper_price_invalid_documents'
export const CAMPER_PRICE_KM_UNLIMITED_NOT_ALLOWED = 'camper_price_km_unlimited_not_allowed'
export const CAMPER_PRICE_NOT_AVAILABLE = 'camper_price_not_available'
export const CAMPER_PRICE_MIN_DAYS_REQUIRED = 'camper_price_min_days_required'
export const CAMPER_PRICE_CLOSED_DAYS = 'camper_price_closed_days'

export const PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END = 'payment_card_expires_before_booking_end'
export const PAYMENT_UNKNOWN = 'payment_unknown'
export const PAYMENT_INVALID_BOOKING_STATE = 'payment_invalid_booking_state'
export const PAYMENT_CAMPER_ALREADY_BOOKED = 'payment_camper_already_booked'
export const PAYMENT_INSURANCE_CONDITIONS_NOT_MET = 'payment_insurance_condition_not_met'
export const PAYMENT_BOOKING_ALREADY_PAID = 'payment_booking_already_paid'
export const PAYMENT_ERROR_IN_SEND_CARD_INFO = 'payment_error_in_send_card_info'
export const PAYMENT_WEB_REDIRECT = 'payment_web_redirect'
export const PAYMENT_SUMMARY_GET_UNKNOWN = 'payment_summary_get_unknown'
export const PAYMENT_SUMMARY_POST_UNKNON = 'payment_summary_post_unknown'
export const PAYMENT_PATCH_PRICES = 'payment_patch_prices'

export const CAMPER_SIMULATION_UNKNOWN = 'camper_simulation_unknown'

export const LEADS_UNKNOWN = 'leads_unknown'

export const INVALID_PLACE = 'invalid_place'
