<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router'

interface Props {
  redirectUrl?: RouteLocationRaw
  country?: string
  canClose?: boolean
}

defineEmits<{ close: [] }>()

const props = withDefaults(defineProps<Props>(), {
  canClose: false,
})

const { t } = useI18n()

const countryString = computed(() => {
  switch (props.country?.toLowerCase()) {
    case 'uk':
    case 'en-gb':
      return t('components.app_yesboony_create_camper.platform_generic_countries.uk')
    case 'nl':
      return t('components.app_yesboony_create_camper.platform_generic_countries.nl')
  }
  return undefined
})
</script>

<template>
  <YscModal
    is-header-left-on-mobile
    :can-close="canClose"
  >
    <AppSupersizingImagesHeader reverse />
    <div class="mt-6 space-y-4">
      <p>{{ $t('components.app_yesboony_create_camper.platform_generic', { country_string: countryString }) }}</p>
      <p>{{ $t('components.app_yesboony_create_camper.create_your_ad_on_goboony') }}</p>
    </div>
    <div class="mt-8 space-y-4 text-center">
      <NuxtLink
        :to="props.redirectUrl"
        class="btn btn-primary"
        target="_blank"
        rel="nofollow"
      >
        {{ $t('components.app_supersizing_redirect.go_to_goboony') }}
      </NuxtLink>
    </div>
  </YscModal>
</template>

<style scoped>
#supersizing-redirect-modal div:first-child {
  @apply w-96;
}
</style>
