<script setup lang="ts">
import { today, getLocalTimeZone } from '@internationalized/date'
import type { SearchProductsQueryParameters } from '@yescapa-dev/ysc-api-js/legacy'
import type { APISearchLocationItem } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import type { PopularDestination } from '../../../ysc-components/dist/runtime/components/YscSearch/YscSearchWidget.vue'
import { YSC_API_SEARCH_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  withSubmitButton?: boolean
  extraQuery?: SearchProductsQueryParameters | null
  isLight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withSubmitButton: false,
  extraQuery: null,
  isLight: false,
})

const emits = defineEmits<{
  'open-supersizing-modal': [{ query: () => void, redirect: string, isBlocking: boolean, countryCode: string }]
}>()

const { localeProperties } = useLocaleProperties()
const { $api } = useYscApi()
const { $errorManager } = useErrorManager()
const route = useRoute()

const { storedLastQueries, sanitizeStoredLastQueries } = useStoredLastQueries()

const showLastQueries = ref(true)
const results = ref<APISearchLocationItem[]>([])

const pendingQuery = ref<Partial<Pick<SearchFilters, 'where' | 'latitude' | 'longitude' | 'date_from' | 'date_to' | 'radius'>>>({
  where: queryValueToString(route.query.where),
  latitude: queryValueToNumber(route.query.latitude),
  longitude: queryValueToNumber(route.query.longitude),
  date_from: queryValueToString(route.query.date_from),
  date_to: queryValueToString(route.query.date_to),
  radius: queryValueToNumber(route.query.radius),
})

const { filters: searchFilters } = storeToRefs(useSearchStore())

const findOneLocalizedContentWithFallback = useFindOneLocalizedContentWithFallback()
const { data: popularDestinations } = await useAsyncData('search-bar-popular-places', async () => await findOneLocalizedContentWithFallback('popular-places'))

const { getSourceUrl } = useTwicpicsImage({ withRefitTransformation: true })
const formattedPopularDestinations = computed(() => {
  return popularDestinations.value.body.map((destination: PopularDestination) => {
    if (!destination.picture) {
      return destination
    }

    const url = getSourceUrl({ path: twicpicsPath(destination.picture), config: { width: 96, height: 96 } })

    return {
      ...destination,
      picture: decodeURIComponent(url.href),
    }
  })
})

const destination = computed(() => pendingQuery.value.where ?? queryValueToString(route.query.where))

const onDestinationQueryChange = async (val: string | null) => {
  if (!val || val.trim().length < 3) {
    showLastQueries.value = true
    return
  }

  val = val.trim()

  showLastQueries.value = false

  try {
    results.value = await $api.search.getLocations({
      search: val,
      language: localeProperties.yscCode,
    })
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_SEARCH_ERROR })
    }
  }
}
const destinationToStore = ref<{ locationId: string | null, address: APISearchLocationItem['address'] | null, countryCode: string | null }>({
  locationId: null,
  address: null,
  countryCode: null,
})

const onDestinationChange = async (args: { locationId: string, label: string, countryCode: string, date_from?: string, date_to?: string, address?: APISearchLocationItem['address'] } | null) => {
  if (!args) return
  const { locationId, label, countryCode: newCountryCode, date_from, date_to, address } = args
  let latitude, longitude, radius
  try {
    const data = await $api.search.getLocationsInfos({ location_id: locationId })
    latitude = data.displayPosition.latitude
    longitude = data.displayPosition.longitude
    radius = data.radius
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_SEARCH_ERROR })
    }
    return
  }

  pendingQuery.value.where = label
  pendingQuery.value.latitude = latitude
  pendingQuery.value.longitude = longitude
  pendingQuery.value.radius = radius

  if (date_from && date_to) {
    onDatesChange({ start: date_from, end: date_to })
  }

  destinationToStore.value.countryCode = newCountryCode
  destinationToStore.value.address = address ?? null
  destinationToStore.value.locationId = locationId
}

const { coords, error, resume } = useGeolocation({ enableHighAccuracy: false, immediate: false })

watch(coords, () => {
  // Test on Number.isFinite because the default value of useGeolocation.coords are Infinity
  if (Number.isFinite(coords.value.latitude) && Number.isFinite(coords.value.longitude)) {
    pendingQuery.value.latitude = coords.value.latitude
    pendingQuery.value.longitude = coords.value.longitude
  }
})

watch(error, () => {
  if (error.value) {
    pendingQuery.value.where = null
    pendingQuery.value.latitude = null
    pendingQuery.value.longitude = null
    return
  }
})

const { t } = useI18n()
const onDestinationAroundMe = () => {
  resume()
  pendingQuery.value.where = t('pages.s.around_me')
  pendingQuery.value.radius = 5000
}

const onDatesChange = (evt: { start?: string | null, end?: string | null } | null) => {
  const { start, end } = evt || {}
  pendingQuery.value.date_from = start
  pendingQuery.value.date_to = end
}

const { searchCountryRedirectedToGoboony } = storeToRefs(useReferentialStore())
const onSubmit = () => {
  const countryIsNotEligbleForBooking = (destinationToStore.value.countryCode !== null && searchCountryRedirectedToGoboony.value.includes(destinationToStore.value.countryCode)) ?? false
  const mustRedirectToGoboony = countryIsNotEligbleForBooking || destinationToStore.value.countryCode === 'NL'

  if (mustRedirectToGoboony) {
    const { localeProperties: { yscCode, domain } } = useLocaleProperties()
    const getDomain = () => {
      switch (yscCode) {
        case LOCALE_CODES.DE:
        case LOCALE_CODES.DE_AT:
        case LOCALE_CODES.DE_CH:
          return 'https://www.goboony.de/campers/'
        case LOCALE_CODES.EN_GB:
        case LOCALE_CODES.EN_IE:
          return 'https://www.goboony.co.uk/campers/'
        case LOCALE_CODES.IT:
        case LOCALE_CODES.IT_CH:
          return 'https://www.goboony.it/campers/'
        case LOCALE_CODES.FR:
        case LOCALE_CODES.FR_CH:
        case LOCALE_CODES.FR_BE:
          return 'https://www.goboony.fr/campers/'
        case LOCALE_CODES.NL:
        case LOCALE_CODES.NL_BE:
          return 'https://www.goboony.nl/campers/'
        default:
          return 'https://www.goboony.com/campers/'
      }
    }

    const parameters = new URLSearchParams({
      country_code: destinationToStore.value.countryCode?.toLowerCase() ?? '',
      utm_medium: 'homepage',
      utm_source: domain ?? '',
      flavor: domain ?? '',
      utm_campaign: getCountryUtmCampainFromCode(destinationToStore.value.countryCode ?? '') ?? '',
    })
    const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value
    if (date_from) {
      parameters.append('starts_at', date_from)
    }
    if (date_to) {
      parameters.append('ends_at', date_to)
    }
    if (latitude) {
      parameters.append('latitude', String(latitude))
    }
    if (longitude) {
      parameters.append('longitude', String(longitude))
    }
    if (radius) {
      parameters.append('radius', String(radius))
    }
    if (where) {
      parameters.append('location', where)
    }
    emits('open-supersizing-modal', { query: goToQuery, redirect: `${getDomain()}?${parameters.toString()}`, isBlocking: countryIsNotEligbleForBooking, countryCode: destinationToStore.value.countryCode ?? '' })
    return
  }
  goToQuery()
}

const goToQuery = () => {
  const query = { ...route.query }
  const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value

  if (date_from && date_to) {
    query.date_from = date_from
    query.date_to = date_to
  }
  else if (query.date_from && query.date_to) {
    delete query.date_from
    delete query.date_to
  }
  if (where) {
    query.where = where
  }
  if (latitude) {
    query.latitude = latitude.toString()
  }
  if (longitude) {
    query.longitude = longitude.toString()
  }
  if (radius) {
    query.radius = radius.toString()
  }

  query.page = '1'
  if (props.extraQuery) {
    for (const [key, value] of Object.entries(props.extraQuery)) {
      query[key] = value
    }
  }

  navigateTo({ name: 's', query })

  searchFilters.value.where = queryValueToString(query.where)
  searchFilters.value.latitude = queryValueToNumber(query.latitude)
  searchFilters.value.longitude = queryValueToNumber(query.longitude)
  searchFilters.value.radius = queryValueToNumber(query.radius)
  searchFilters.value.date_from = queryValueToString(query.date_from)
  searchFilters.value.date_to = queryValueToString(query.date_to)

  storedLastQueries.value = sanitizeStoredLastQueries([{ locationId: destinationToStore.value.locationId, label: where, countryCode: destinationToStore.value.countryCode, date_from, date_to, address: destinationToStore.value.address }, ...storedLastQueries.value])

  results.value = []
}
</script>

<template>
  <YscSearchWidget
    :results="results"
    :stored-last-queries="storedLastQueries"
    :destination="destination ?? undefined"
    :dates="{ start: pendingQuery.date_from ?? undefined, end: pendingQuery.date_to ?? undefined }"
    :min-date="today(getLocalTimeZone()).toString()"
    :is-light="isLight"
    :popular-destinations="formattedPopularDestinations"
    @destination-query-change="onDestinationQueryChange"
    @destination-change="onDestinationChange"
    @around-me="onDestinationAroundMe"
    @dates-change="onDatesChange"
    @submit="onSubmit"
  />
</template>
