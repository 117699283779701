<script setup lang="ts">
import type { Picture } from '@yescapa-dev/ysc-api-js/legacy'
import { YSC_API_REVIEW_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  idReview: number | string
  note: number | null
  photos?: Picture[]
  isLargePhotos?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  photos: () => [],
  isLargePhotos: false,
})
const slots = useSlots()
const emits = defineEmits<{
  'photo-click': [index: string]
}>()

const isReportModal = ref(false)
const isReviewSent = ref(false)

const { sendingForm, setSendingForm } = useSendingForm()
provide(setSendingFormKey, { setSendingForm })

const { $api } = useYscApi()
const onSendReportReview = async () => {
  try {
    await $api.reviews.reportReview(props.idReview)
  }
  catch (e) {
    if (e instanceof Error) {
      const { $errorManager } = useErrorManager()
      $errorManager({ e, name: YSC_API_REVIEW_ERROR })
    }
    return
  }

  isReviewSent.value = true
  isReportModal.value = false
}

const { isLoggedIn } = storeToRefs(useUserStore())
</script>

<template>
  <div class="max-w-prose space-y-4">
    <div class="flex items-center space-x-4">
      <slot name="avatar" />

      <div class="grow">
        <p
          v-if="slots['user-name']"
          class="font-semibold"
        >
          <slot name="user-name" />
        </p>
        <div class="mt-1 flex items-center justify-between">
          <div class="flex items-center">
            <YscIconsStarSolid class="mr-1 inline h-4 w-4 text-pink-500" />
            {{ note }}
          </div>
          <p
            v-if="$slots['user-description']"
            class="text-right text-sm text-gray-500"
          >
            <slot name="user-description" />
          </p>
        </div>
      </div>
    </div>

    <div v-if="slots.description">
      <slot name="description" />

      <template v-if="isLoggedIn">
        <div
          v-if="isReviewSent"
          class="text-gray-500 flex items-center"
        >
          <YscIconsCheck class="w-4 h-4 mr-1" />
          <span>
            {{ $t('components.app_review.review_sent') }}
          </span>
        </div>
        <button
          v-else
          class="text-gray-500 text-sm underline"
          @click="isReportModal = true"
        >
          {{ $t('components.app_review.report') }}
        </button>
      </template>
    </div>

    <AppContentScroller
      v-if="photos.length"
      class="space-x-4"
    >
      <TwicImg
        v-for="photo in photos"
        :key="photo.id"
        :src="twicpicsPath(photo.path)"
        :ratio="isLargePhotos ? '4/3' : '1'"
        :class="isLargePhotos ? 'w-36' : 'w-20'"
        :alt="$t('commons.links.community')"
        class="shrink-0 rounded"
        placeholder="maincolor"
        @click.prevent="emits('photo-click', photo.path)"
      />
    </AppContentScroller>

    <div
      v-if="slots['private-message']"
      class="space-y-4 rounded-lg bg-gray-50 p-4"
    >
      <div class="flex items-center space-x-2">
        <YscIconsLock class="h-4 w-4 text-red-600" />
        <span class="text-sm font-semibold text-black">{{ $t('data.review.private_text.label') }}</span>
      </div>

      <slot name="private-message" />
    </div>
  </div>
  <Teleport to="body">
    <YscModal
      v-if="isReportModal"
      @close="isReportModal = false"
    >
      <template #title>
        {{ $t('components.app_review.report_a_review') }}
      </template>
      <div class="space-y-4">
        <h4 class="font-semibold">
          {{ $t('components.app_review.wish_to_report') }}
        </h4>
        <p class="text-gray-500 text-sm">
          <slot name="description" />
        </p>
      </div>

      <template #footer>
        <div class="flex justify-between">
          <button
            class="link"
            @click="isReportModal = false"
          >
            {{ $t('commons.actions.cancel') }}
          </button>
          <YscForm
            id="report-review"
            :submit-action="onSendReportReview"
          >
            <YscButtonSubmit
              class="btn btn-primary"
              :loading="sendingForm"
            >
              {{ $t('commons.actions.submit') }}
            </YscButtonSubmit>
          </YscForm>
        </div>
      </template>
    </YscModal>
  </Teleport>
</template>
