<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router'

interface Props {
  keepNavigateAction?: (() => void)
  redirectUrl?: RouteLocationRaw
  blocking: boolean
  country?: string
}

defineEmits<{ close: [] }>()

const props = defineProps<Props>()

const { t } = useI18n()
const introductionString = computed(() => {
  switch (props.country) {
    case 'NL':
      return t('components.app_supersizing_redirect.introduction_dynamic', { to_country_string: t(`components.app_supersizing_redirect.countries.to_nl`) })
    case 'GB':
      return t('components.app_supersizing_redirect.introduction_dynamic', { to_country_string: t(`components.app_supersizing_redirect.countries.to_uk`) })
    default:
      return t('components.app_supersizing_redirect.introduction')
  }
})
</script>

<template>
  <YscModal
    id="supersizing-redirect-modal"
    is-header-left-on-mobile
    @close="$emit('close')"
  >
    <AppSupersizingImagesHeader />
    <div class="mt-6 space-y-4 text-center">
      <p>{{ introductionString }}</p>
      <i18n-t
        keypath="components.app_supersizing_redirect.description_dynamic"
        tag="p"
        scope="global"
      >
        <template #visit_goboony_string>
          <span class="font-semibold">{{ $t('components.app_supersizing_redirect.visit_goboony') }}</span>
        </template>
      </i18n-t>
    </div>
    <div class="mt-8 space-y-4 text-center">
      <NuxtLink
        :to="props.redirectUrl"
        external
        class="btn btn-primary"
      >
        {{ $t('components.app_supersizing_redirect.go_to_goboony') }}
      </NuxtLink>
      <button
        v-if="!blocking"
        class="link text-sm text-gray-500"
        @click="() => keepNavigateAction && keepNavigateAction()"
      >
        {{ $t('components.app_supersizing_redirect.stay_on_yescapa') }}
      </button>
    </div>
  </YscModal>
</template>

<style scoped>
#supersizing-redirect-modal div:first-child {
  @apply w-96;
}
</style>
